<template>
  <div class="auth">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card shadow">
            <div class="card-body">
              <h4 class="card-title bordered">TRADESK</h4>
              <h5 class="mb-4 text-center text-secondary">LOGIN</h5>
              <div class="alert alert-danger" v-if="has_error">Invalid email and/or password</div>
              <form @submit.prevent="login">
                <div class="form-group">
                  <label for="email">Email</label>
                  <input type="text" placeholder="Email" id="email" class="form-control" v-model="user.email">
                </div>
                <div class="form-group">
                  <label for="email">Password</label>
                  <input type="password" placeholder="******" class="form-control" v-model="user.password">
                </div>
                <div class="form-group text-right">
                  <button class="btn btn-theme"><i class="lni-lock mr-2"></i> Login</button>
                </div>
              </form>
              <p><router-link :to="{ name: 'register' }"><i class="lni-user mr-3"></i> Don't have an account? Register.</router-link></p>
              <p><router-link :to="{ name: 'forgot-password' }"><i class="lni-bolt mr-3"></i> Forgot your password?.</router-link></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        email: '',
        password: ''
      },
      has_error: false
    }
  },

  methods: {
    login() {
      this.has_error = false
      this.$loader.start()

      this.$axios.post('/api/v1/login', this.user).then(response => {
        this.$auth.setToken(response.data.access_token)
        window.location.href = this.$router.resolve({ name: 'dashboard' }).href
      }).catch(() => {
        this.has_error = true
        this.$loader.stop()
      })
    }
  }
}
</script>
